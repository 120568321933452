import { AnimatePresence, motion } from 'framer-motion';
import { Modal, Box, Button, Stack, Typography, IconButton, Input, Divider, Checkbox, FormHelperText, Link, Textarea, ListItem, List, ListItemDecorator, Sheet, ListItemContent, Switch, ListDivider, ListItemButton, switchClasses, sheetClasses, FormControl } from '@mui/joy';
import { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../context';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { AddBoxRounded } from '@mui/icons-material';
import InputPhone from '../InputPhone/InputPhone';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { Link as LinkRouter } from 'react-router-dom';
import MapBlock from './MapBlock';

import PinDropRoundedIcon from '@mui/icons-material/PinDropRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';


export default function ModalOrderCreate({ isOpen, onClose }) {
    const { modalBasket, setModalBasket } = useContext(LayoutContext);
    const { modalOrderCreate, setModalOrderCreate } = useContext(LayoutContext);

    const [ name, setName ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ dateBirth, setDateBirth ] = useState('');

    function home(){
        setModalOrderCreate(false);
        setModalBasket(false);
    }
    
    useEffect(() => {
        if (modalBasket | modalOrderCreate) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
        // Очистка при размонтировании компонента
        return () => document.body.style.overflow = 'auto';
      }, [modalBasket, modalOrderCreate]);
    
    return (
        <AnimatePresence>
            {modalOrderCreate && (
                <>
                    {/* Затемняющий фон */}
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backdropFilter: 'blur(10px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 10,
                        }}
                    />
                    <motion.div
                        initial={{ x: '100%', opacity: 0  }} // Начальное положение и прозрачность
                        animate={{ x: 0, opacity: 1 }} // Анимация открытия
                        exit={{ x: 400, opacity: 0 }} // Анимация закрытия
                        transition={{ duration: 0.3, ease: "easeOut" }}
                        style={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: '100%',
                            backgroundColor: 'white',
                            zIndex: 11,
                            overflow: 'auto',
                          }} // Длительность анимации
                    >
                        <FormControl
                            sx={{
                                m: 2,
                                mb: 4
                            }}
                        >
                            <Stack
                                direction='row'
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={{ xs: 1, sm: 2 }}
                                sx={{
                                    mb: 2
                                }}
                            >
                                <Typography level='h2'>
                                    Оформление
                                </Typography>
                                <IconButton variant="soft" color="danger" onClick={() => home()} sx={{ height: 'fit-content' }}>
                                    <HomeRoundedIcon />
                                </IconButton>
                            </Stack>
                            <Divider/>

                            <Button sx={{ mt: 2, width: 'fit-content' }} startDecorator={<ArrowBackIosRoundedIcon/>} color="primary" variant="plain" onClick={() => setModalOrderCreate(false)}>
                                Корзина
                            </Button>

                            <Stack
                                justifyContent="space-between"
                                alignItems="stretch"
                                spacing={{ xs: 1, sm: 2 }}
                                direction={{ xs: 'column', sm: 'row' }}
                                sx={(theme) => ({
                                    p: 1,
                                    mb: 1,
                                })}
                            >   
                                <Stack
                                    direction='column'
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Box sx={{ width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-md' fontWeight={'xl'}>
                                            Адрес
                                        </Typography>
                                        <MapBlock />
                                        <Input type="text" placeholder="Луначарского, 99" variant="soft" sx={{ mt: 2 }} />
                                        
                                        <List
                                            aria-labelledby="ios-example-demo"
                                            variant='outlined'
                                            sx={(theme) => ({
                                                mt: 2,
                                                borderColor: 'background.level1',
                                                '& ul': {
                                                    '--List-gap': '0px',
                                                    // bgcolor: 'background.surface',
                                                    '& > li:first-child > [role="button"]': {
                                                        borderTopRightRadius: 'var(--List-radius)',
                                                        borderTopLeftRadius: 'var(--List-radius)',
                                                    },
                                                    '& > li:last-child > [role="button"]': {
                                                        borderBottomRightRadius: 'var(--List-radius)',
                                                        borderBottomLeftRadius: 'var(--List-radius)',
                                                    },
                                                },
                                                '--List-radius': theme.radius.sm,
                                                '--List-gap': '1rem',
                                                '--ListDivider-gap': '0px',
                                                '--ListItem-paddingY': '0.5rem',
                                                // override global variant tokens
                                                '--joy-palette-neutral-plainHoverBg': 'rgba(0 0 0 / 0.08)',
                                                '--joy-palette-neutral-plainActiveBg': 'rgba(0 0 0 / 0.12)',
                                                [theme.getColorSchemeSelector('light')]: {
                                                    '--joy-palette-divider': 'rgba(0 0 0 / 0.08)',
                                                },
                                                [theme.getColorSchemeSelector('dark')]: {
                                                    '--joy-palette-neutral-plainHoverBg': 'rgba(255 255 255 / 0.1)',
                                                    '--joy-palette-neutral-plainActiveBg': 'rgba(255 255 255 / 0.16)',
                                                },
                                            })}
                                        >
                                            <ListItem nested>
                                                <List
                                                    aria-label="Network"
                                                    sx={{
                                                        [`& .${sheetClasses.root}`]: {
                                                            p: 0.8,
                                                            lineHeight: 0,
                                                            borderRadius: 'sm',
                                                        },
                                                    }}
                                                >
                                                    <ListItem>
                                                        <ListItemButton>
                                                            <ListItemDecorator>
                                                            <Sheet variant="solid" color="primary">
                                                                <PinDropRoundedIcon />
                                                            </Sheet>
                                                            </ListItemDecorator>
                                                            <ListItemContent sx={{ fontSize: 'sm' }}>
                                                                Луначарского, 99
                                                            </ListItemContent>
                                                            <Button
                                                                color='danger'
                                                                sx={{ px: 1 }}
                                                                size='sm' 
                                                                variant="soft"
                                                                onClick={() => alert("DEL")}
                                                            >
                                                                <DeleteOutlineRoundedIcon />
                                                            </Button>
                                                            {/* <ArrowForwardIosRoundedIcon fontSize="xl1" /> */}
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <ListDivider inset="startContent" />
                                                    <ListItem>
                                                        <ListItemButton>
                                                            <ListItemDecorator>
                                                            <Sheet variant="solid" color="primary">
                                                                <PinDropRoundedIcon />
                                                            </Sheet>
                                                            </ListItemDecorator>
                                                            <ListItemContent sx={{ fontSize: 'sm' }}>
                                                                Луначарского, 99
                                                            </ListItemContent>
                                                            <Button
                                                                color='danger'
                                                                sx={{ px: 1 }}
                                                                size='sm' 
                                                                variant="soft"
                                                                onClick={() => alert("DEL")}
                                                            >
                                                                <DeleteOutlineRoundedIcon />
                                                            </Button>
                                                            {/* <ArrowForwardIosRoundedIcon fontSize="xl1" /> */}
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <ListDivider inset="startContent" />
                                                    <ListItem>
                                                        <ListItemButton>
                                                            <ListItemDecorator>
                                                            <Sheet variant="solid" color="primary">
                                                                <PinDropRoundedIcon />
                                                            </Sheet>
                                                            </ListItemDecorator>
                                                            <ListItemContent sx={{ fontSize: 'sm' }}>
                                                                Луначарского, 99
                                                            </ListItemContent>
                                                            <Button
                                                                color='danger'
                                                                sx={{ px: 1 }}
                                                                size='sm' 
                                                                variant="soft"
                                                                onClick={() => alert("DEL")}
                                                            >
                                                                <DeleteOutlineRoundedIcon />
                                                            </Button>
                                                            {/* <ArrowForwardIosRoundedIcon fontSize="xl1" /> */}
                                                        </ListItemButton>
                                                    </ListItem>
                                                </List>
                                            </ListItem>
                                        </List>
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-md' fontWeight={'xl'}>
                                            Комментарий к заказу
                                        </Typography>
                                        <Textarea minRows={3} variant="soft" placeholder="Без комментариев" />
                                    </Box>

                                    <Box sx={{ width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-md' fontWeight={'xl'}>
                                            Имя*
                                        </Typography>
                                        <Input type="text" onChange={(e) => setName(e.target.value)} value={name} placeholder="Иван" variant="soft" />
                                    </Box>
                                    {/* <Box sx={{ width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-md' fontWeight={'xl'}>
                                            Номер телефона*
                                        </Typography>
                                        <InputPhone phone={phone} setPhone={setPhone}/>
                                    </Box> */}

                                    <Box sx={{ width: '100%' }}>
                                        <Checkbox
                                            label={
                                            <Box>
                                                <Typography level="body-sm" lineHeight={1}>Я соглашаюсь с политикой ресурса BaitRide</Typography>
                                            </Box>
                                            }
                                        />
                                        <FormHelperText sx={{ my: 0 }}>
                                            <Typography level="body-xs">
                                                Читать <LinkRouter to="/policy"><Link color='primary'>пользовательское соглашение</Link></LinkRouter>.
                                            </Typography>
                                        </FormHelperText>
                                    </Box>
                                </Stack>
                            </Stack>
                            
                            <Box
                                sx={(theme) => ({
                                    p: 1,
                                    // boxShadow: 'md',
                                    backdropFilter: 'blur(8px)',
                                    backgroundColor: '#ffffff59',
                                    position: 'sticky', bottom: 30,
                                    borderRadius: 'md',
                                })}
                            >
                                <Stack
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    spacing={{ xs: 1, sm: 2 }}
                                    direction={{ xs: 'column', sm: 'row' }}
                                    sx={(theme) => ({
                                        // p: 1,
                                        mb: 1
                                        // boxShadow: 'md',
                                        // backdropFilter: 'blur(10px)',
                                        // backgroundColor: theme.vars.palette.neutral[50],
                                        // position: 'sticky', bottom: 10,
                                        // borderRadius: 'md',
                                    })}
                                >   
                                    <Stack
                                        direction='row'
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={4}
                                    >
                                        
                                        <Stack
                                            sx={{ height: '100%', width: '100%' }}
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                            spacing={0.5}
                                        >
                                            <Typography level='h3'>
                                                Итог:
                                            </Typography>
                                            <Typography fontWeight="xl" level="h3">
                                                1000 ₽
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>

                                <Button 
                                    color='primary'
                                    size="lg"
                                    onClick={home}
                                    sx={{
                                        height: 40,
                                        fontSize: 'lg',
                                        width: { xs: '100%', lg: 'auto' }
                                    }}
                                >
                                    Заказать
                                </Button>
                            </Box>
                        </FormControl>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    )
}