

import { useTheme } from "@emotion/react";
import { Box, Button, Input } from "@mui/joy";
import { useEffect, useRef } from "react";
import { CountrySelector, PhoneInput, usePhoneInput } from "react-international-phone";
import "react-international-phone/style.css";



export default function InputPhone({ phone, setPhone }) {
    const theme = useTheme();

    return (
        <PhoneInput
            defaultCountry="ru"
            phone={phone}
            onChange={setPhone}
            style={{
                width: '100%',
                transition: '.3s'
            }}
        />
    )
}