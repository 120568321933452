import { CSSTransition, Transition } from 'react-transition-group';
import Modal from '@mui/joy/Modal';
import { ModalOverflow } from '@mui/joy';

import { useContext, useEffect, useRef, useState } from 'react';

// import DialogAuth from './Dialogs/DialogAuth';
import DialogAccount from './Dialogs/DialogAccount';
// import DialogCode from './Dialogs/DialogCode';
// import DialogRegistration from './Dialogs/DialogRegistration';
import { LayoutContext } from '../../context';



// -=-=- Модальное окно для аккаунта -=-=-
export default function ModalAccount () {
    // const [ open, setOpen ] = useState(false);
    const { user } = useContext(LayoutContext);

    const { modalAccountOpen, setModalAccountOpen } = useContext(LayoutContext);

    const [ phone, setPhone ] = useState('');

    const [ textModal, setTextModal ] = useState('');

    const statusStage = {
        account: 'account',
        auth: 'auth',
        reg: 'reg',
        code: 'code',
    };
    const [ stageModal, setStageModal ] = useState(statusStage.auth);

    useEffect(() => {
        console.log('[modalAccount]', user);
        if (Object.keys(user).length) {
            setStageModal(statusStage.account);
        }
    }, [user]);
    

    return (
        <Transition in={modalAccountOpen} timeout={500}>
            {(state) => (
                <Modal
                    keepMounted
                    open={!['exited', 'exiting'].includes(state)}
                    onClose={() => setModalAccountOpen(false)}
                    slotProps={{
                        backdrop: {
                            sx: {
                                padding: 0,
                                opacity: 0,
                                backdropFilter: 'none',
                                transition: `opacity 200ms, backdrop-filter 500ms`,
                                ...{
                                    entering: { opacity: 1, backdropFilter: 'blur(8px)' },
                                    entered: { opacity: 1, backdropFilter: 'blur(8px)' },
                                }[state],
                            },
                        },
                    }}
                    sx={{
                        visibility: state === 'exited' ? 'hidden' : 'visible',
                    }}
                >   
                    <ModalOverflow sx={{ height: 'auto' }}>
                        <CSSTransition in={modalAccountOpen} timeout={300} classNames={'account'}>
                        {/* stageModal == statusStage.account ? */}
                        {
                                 <DialogAccount state={state} setOpen={setModalAccountOpen} setStage={setStageModal} status={statusStage}/>
                            // : 
                            // stageModal == statusStage.auth ?
                            //     <DialogAuth state={state} setStage={setStageModal} status={statusStage} setTextModal={setTextModal} phone={phone} setPhone={setPhone}/>
                            // :    
                            // stageModal == statusStage.reg ?
                            //     <DialogRegistration state={state} setModalAccount={setModalAccount} setStage={setStageModal} status={statusStage} setTextModal={setTextModal} phone={phone} setPhone={setPhone}/>
                            // :
                            //     <DialogCode state={state} setOpen={setOpen} setStage={setStageModal} status={statusStage} text={textModal} phone={phone}/>
                            }
                        </CSSTransition>
                    </ModalOverflow>
                </Modal>
            )}
        </Transition>
    )
}
