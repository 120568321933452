import React, { createContext, useEffect, useState } from 'react';
import texts from './texts';
import { translateText, translateTextObject } from './utils';


// Создаем контекст с начальным значением
export const LayoutContext = createContext();


// Создаем провайдер, который будет оборачивать все компоненты
export const LayoutProvider = ({ children }) => {
    const [ theme, setTheme ] = useState('light');

    const [ loadLang, setLoadLang ] = useState(false);
    const [ language, setLanguage ] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'en');
    const [ textTranslate, setTextTranslate ] = useState(texts);

    const [ user, setUser ] = useState({});
    const [ modalOpen, setOpenModal ] = useState(false);
    const [ modalAccountOpen, setModalAccountOpen ] = useState(false);
    const [ modalBasket, setModalBasket ] = useState(false);
    const [ modalOrderCreate, setModalOrderCreate ] = useState(false);
    const [ modalCompany, setModalCompany ] = useState(false);
    const [ modalOrders, setModalOrders ] = useState(false);

    const [ webApp, setWebApp ] = useState(undefined);

    const [ basket, setBasket ] = useState([]);

    const [ modalProduct, setModalProduct ] = useState({});

    // Функция для переключения темы
    const toggleTheme = () => {
      setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    // Отслеживаем изменение текстов
    // useEffect(() => {
    //   localStorage.setItem('language', language);
    // }, [textTranslate]);

    // Если происходит смена языка
    useEffect(() => {
      const loadTranslations = async () => {
        console.log('aaaaa');
        console.log('Lang', localStorage.getItem('language'));
        console.log('Lang2', language);
        console.log(localStorage.getItem('language') == language);
        console.log(localStorage.getItem('languageTranslate'));
        
        localStorage.setItem('language', language);
        setTextTranslate(texts[language]);
        // if (localStorage.getItem('language') == language && localStorage.getItem('languageTranslate')){
        //   setTextTranslate(JSON.parse(localStorage.getItem('languageTranslate')));
        //   console.log(JSON.parse(localStorage.getItem('languageTranslate')));
        // }
        // else {
        //   setLoadLang(true);
        //   console.log('QQQQ', textTranslate);
        //   const fff = await translateTextObject(texts[language], language);
        //   localStorage.setItem('language', language);
        //   setLanguage(language);
        //   console.log('jjjj', fff);
        //   setTextTranslate(fff);
        //   setLoadLang(false);
        // }
      };
      loadTranslations();
    }, [language]);

    // -=-=- Загрузка WebApp -=-=-
    useEffect(() => {
      setWebApp(window.Telegram.WebApp);
      // Сообщаем Telegram, что WebApp готово
      webApp?.ready();
      // Разворачиваем WebApp на весь экран
      webApp?.expand();
    }, []);
    // -=-=-=-=-=-=-=-=-=-=-=-


    const _values = {
        theme, toggleTheme,
        user, setUser,
        
        modalOpen, setOpenModal,
        modalProduct, setModalProduct,
        modalAccountOpen, setModalAccountOpen,
        modalBasket, setModalBasket,
        modalOrderCreate, setModalOrderCreate,
        modalCompany, setModalCompany,
        modalOrders, setModalOrders,

        basket, setBasket,
        webApp, setWebApp,

        loadLang, setLoadLang,
        language, setLanguage,
        textTranslate, setTextTranslate,
        webApp, setWebApp,

        loadLang, setLoadLang,
        language, setLanguage,
        textTranslate, setTextTranslate,
    }
  
    return (
      <LayoutContext.Provider value={_values}>
        {children}
      </LayoutContext.Provider>
    );
};