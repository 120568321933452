import { Box, Button, Card, CircularProgress, IconButton, Stack, Typography } from "@mui/joy";
import { useContext, useState } from "react";
import { LayoutContext } from "../../context";

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

export default function BasketItem({ item }) {
    const [ count, setCount ] = useState(1);
    const { menu, setMenu } = useContext(LayoutContext);

    const [ image, setImage ] = useState(undefined);

    // // Подгружаем картинку товара
    // useEffect(() => {
    //     setImage(API_URL + item.product?.image);
    //     if (item?.product?.image !== null) {
    //         setImage(API_URL + item?.product?.image);
    //     } else setImage(undefined);
    //     // loadImageProduct(item.product, setImage, menu, setMenu);
    // }, [])


    function delBask (){
        // removeBasket(0, item.product);
        // item.setCount(item.count - 1);
        setCount(count - 1);
    }

    function plusBask (){
        // addBasket(item.count + 1, item.setCount, item.product);
        // item.setCount(item.count + 1);
        setCount(count + 1);
    }

    return (
        <Stack
            justifyContent="space-between"
            alignItems="stretch"
            spacing={{ xs: 1, sm: 2 }}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{
                p: 2,
                boxShadow: 'md',
                borderRadius: 'md'
            }}
        >   
            <Stack
                direction='row'
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >
                { image == '' ?
                    <CircularProgress />
                    :
                    <img style={{ objectFit: 'cover', borderRadius: 'var(--joy-radius-xl)' }} loading='lazy' src={ image == undefined ? '/default.png' : image } width={80} height={80} alt="" />
                }
                <Stack
                    sx={{ height: '100%' }}
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0.5}
                >
                    <Box>
                        <Typography level='title-lg' fontWeight='xl'>
                            {/* {item?.product?.mitm_Name} { item?.product?.mitm_Article ? `(${item?.product?.mitm_Article?.split('|')[1]})` : "" } */}
                            Огурец
                        </Typography>
                        {/* <Typography fontWeight='md'>
                            Вес: <Typography sx={{ color: '#C30013' }}>120г</Typography>
                        </Typography> */}
                    </Box>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={-0.8}
                    >
                        <Typography>
                            Стоимость:
                        </Typography>
                        <Typography fontWeight='xl' level="h4">
                            {/* {item?.product?.mitm_Price} ₽ */}
                            1000 ₽
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>

            {
                count <= 0 ?
                    <Button 
                        sx={{ fontSize: { xs: 'sm', sm: 'md' }, width: { xs: '100%', lg: 'auto' } }}
                        onClick={() => plusBask()}>
                        В корзину
                    </Button>
                :
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={0.8}
                >
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            justifyContent: 'flex-end',
                            alignItems: "center",
                        }}
                    >
                        <IconButton variant="solid" color="primary" onClick={() => delBask()}>
                            <RemoveRoundedIcon />
                        </IconButton>
                        <Button variant="solid" disabled color="danger" 
                            sx={(theme) => ({
                                py: 0,
                                fontSize: 'lg',
                                width: 'auto',
                                [theme.breakpoints.down('sm')]: {
                                    width: '100%'
                                }
                            })}
                        >
                            {count}
                        </Button>
                        <IconButton variant="solid" color="primary" onClick={() => plusBask()}>
                            <AddRoundedIcon />
                        </IconButton>
                    </Stack>
                    <Button 
                        color='danger'
                        sx={{ px: 1 }} 
                        variant="soft"
                        onClick={() => delBask()}>
                        <DeleteOutlineRoundedIcon sx={{ fontSize: 20 }} />
                    </Button>
                </Stack>
            }
        </Stack>
    )
}