

export const LINK_TRANSLATE = "http://localhost:5000";


export const textStock = {
  lang_load: 'Loading a new language...'
}


// -=-=-=- Шаблоны текстов -=-=-=-
const texts = {
    ru: {
      search: 'Поиск',
      category: 'Категория',
      categories: 'Категории',
      account: 'Профиль',
      name: 'Имя',
      ivan: 'Иван',
      phone: 'Номер телефона',
      date_birthday: 'Дата рождения',
      language: 'Язык',
      save: 'Сохранить',
      price: 'Стоимость',
      design: 'Design',
      summary: 'Итог',
      currency: {
        ru: '₽',
        si: '₹',
        en: '₹'
      },
      basket: 'Корзина',
      address: 'Адрес',
      comment_order: 'Коментарий к заказу',
      no_comment: 'Без комментариев',
      order: 'Заказ',
      orders: 'Заказы',
      all: 'Все',
      delivered: 'Доставляется',
      loading: 'Загрузка'
    },
    en: {
      search: 'Search',
      category: 'Category',
      categories: 'Categories',
      account: 'Profile',
      name: 'Name',
      ivan: 'Ivan',
      phone: 'Phone number',
      date_birthday: 'Date Birthday',
      language: 'Language',
      save: 'Save',
      price: 'Price',
      design: 'Design',
      summary: 'Summary',
      currency: {
        ru: '₽',
        si: '₹',
        en: '₹'
      },
      basket: 'Basket',
      address: 'Address',
      comment_order: 'Comment to order',
      no_comment: 'No comment',
      order: 'Order',
      orders: 'Orders',
      all: 'All',
      delivered: 'Delivered',
      loading: 'Loading'
    },
    si: {
      search: 'සොයන්න',
      category: 'ප්රවර්ගය',
      categories: 'වර්ග',
      account: 'පැතිකඩ',
      name: 'නම',
      ivan: 'අයිවන්',
      phone: 'දුරකථන අංකය',
      date_birthday: 'දිනය උපන් දිනය',
      language: 'භාෂාව',
      save: 'සුරකින්න',
      price: 'මිල',
      design: 'Design',
      summary: 'සාරාංශය',
      currency: {
        ru: '₽',
        si: '₹',
        en: '₹'
      },
      basket: 'කූඩය',
      address: 'ලිපිනය',
      comment_order: 'ඇණවුම් කිරීමට අදහස් දක්වන්න',
      no_comment: 'ප්රතිචාරයක් නැත',
      order: 'ඇණවුම් කරන්න',
      orders: 'නියෝග',
      all: 'සියල්ල',
      delivered: 'භාර දුන්නා',
      loading: 'පැටවීම'
    },

    // basket: {
    //   contact: 'Contact Us',
    //   rights: 'All rights reserved.',
    // },
    // buttonLabels: {
    //   submit: 'Submit',
    //   cancel: 'Cancel',
    //   save: 'Save Changes',
    // },
    // errors: {
    //   requiredField: 'This field is required.',
    //   invalidEmail: 'Please enter a valid email address.',
    // },
};
  
export default texts;