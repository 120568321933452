import { AnimatePresence, motion } from 'framer-motion';
import { Modal, Box, Button, Stack, Typography, IconButton, Input, Divider } from '@mui/joy';
import { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../context';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { AddBoxRounded } from '@mui/icons-material';
import OrdersItem from './OrdersItem';


export default function ModalOrders() {
    const { modalOrders, setModalOrders } = useContext(LayoutContext);
    
    useEffect(() => {
        if (modalOrders) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
        // Очистка при размонтировании компонента
        return () => document.body.style.overflow = 'auto';
      }, [modalOrders]);
    
    return (
        <AnimatePresence>
            {modalOrders && (
                <>
                    {/* Затемняющий фон */}
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backdropFilter: 'blur(10px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 10,
                        }}
                    />
                    <motion.div
                        initial={{ y: '100%', opacity: 0  }} // Начальное положение и прозрачность
                        animate={{ y: 0, opacity: 1 }} // Анимация открытия
                        exit={{ y: 400, opacity: 0 }} // Анимация закрытия
                        transition={{ duration: 0.3, ease: "easeOut" }}
                        style={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: '100%',
                            backgroundColor: 'white',
                            zIndex: 11,
                            overflow: 'auto',
                          }} // Длительность анимации
                    >
                        <Box
                            sx={{
                                m: 2,
                            }}
                        >
                            <Stack
                                direction='row'
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={{ xs: 1, sm: 2 }}
                                sx={{
                                    my: 1, mb: 2
                                }}
                            >
                                <Typography level='h2' sx={{ mb: 2 }}>
                                    Заказы
                                </Typography>
                                <Stack
                                    direction='row'
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={{ xs: 1, sm: 2 }}
                                    sx={{
                                        my: 1,
                                    }}
                                >
                                    <IconButton variant="soft" color="danger" onClick={() => setModalOrders(false)} sx={{ height: 'fit-content' }}>
                                        <CloseRoundedIcon />
                                    </IconButton>
                                </Stack>
                            </Stack>
                            <Divider/>

                            <Stack
                                justifyContent="space-between"
                                alignItems="stretch"
                                spacing={{ xs: 1, sm: 2 }}
                                direction={{ xs: 'column', sm: 'row' }}
                                sx={{ my: 2 }}
                            >
                                <OrdersItem/>
                                <OrdersItem/>
                                <OrdersItem/>
                                <OrdersItem/>
                                <OrdersItem/>
                                <OrdersItem/>
                                <OrdersItem/>
                            </Stack>
                        </Box>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    )
}