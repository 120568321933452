import { AspectRatio, Button, Sheet, Card, CardActions, CardContent, CardOverflow, Divider, IconButton, Typography, Stack, Box, ButtonGroup, Link } from "@mui/joy";

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { useContext, useState } from "react";
import { motion } from "framer-motion";
import { LayoutContext } from "../../context";
import { AnimatePresence } from "framer-motion";
import ModalProduct from "../ModalProduct/ModalProduct";
import { Link as LinkRouter } from "react-router-dom";



export default function ItemProduct({ item }) {
    const { modalOpen, setOpenModal, setModalProduct } = useContext(LayoutContext);
    const { modalCompany, setModalCompany } = useContext(LayoutContext);

    const [ count, setCount ] = useState(0);

    const handle = () => {
        setModalProduct(item);
        setOpenModal(true);
    };

    function addCount() {
        setCount(count+1);
    }

    function remCount() {
        if (count-1 <= 0) setCount(0);
        setCount(count-1);
    }

    const translateDish = async () => {
        const text = "Hello";
        const sourceLang = "en";
        const targetLang = "ru";
        // Логика вызова API для перевода текста
        const response = await fetch(`https://lingva.ml/api/v1/${sourceLang}/${targetLang}/${encodeURIComponent(text)}`);
        const data = await response.json();
        console.log(data);
        console.log(data.translation);
        // return data.translatedText;
    };

    return (
        <Card 
            variant="plain"
            size="sm"
            sx={{
                // backgroundColor: 'transparent',
                // boxShadow: 'none',
                transition: '.3s ease-in-out',
                transition: 'transform .3s ease-in-out',
                '&:hover': { 
                    transform: 'scale(1.03)', 
                    transition: 'transform .3s ease-in-out',
                    borderColor: 'neutral.outlinedHoverBorder' 
                }
            }}
        >
            <CardOverflow sx={{ cursor: 'pointer' }} onClick={() => setOpenModal(true)}>
                <AspectRatio ratio="2"sx={{
                    '& > .MuiAspectRatio-content': {
                        height: {
                            xs: 40,
                            sm: 60
                        },
                        // ...( image === '' && { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, height: '177px' })
                    }
                }}>
                    <img
                        src={ item?.img ? item?.img : '/default.png' }
                        loading="lazy"
                        alt=""
                    />
                </AspectRatio>
            </CardOverflow>

            <CardContent>
                <Typography level="body-md" lineHeight={1.1} sx={{ cursor: 'pointer', textAlign: 'left' }} onClick={() => setOpenModal(true)}>
                    {item?.title}
                </Typography>
                <Link color="primary" level="body-sm" onClick={() => setModalCompany(true) }>
                    Shri Lanka Cafe
                </Link>
                <Typography level="h3" color="primary" sx={{ textAlign: 'left' }}>1000 Р</Typography>
            </CardContent>

            {
                count <= 0 ?
                    <Button 
                        sx={{ fontSize: { xs: 'sm', sm: 'md' }, width: { xs: '100%', lg: 'auto' } }}
                        onClick={() => addCount()}>
                        В корзину
                    </Button>
                :
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: { xs: 'center', sm: 'flex-end' },
                        alignItems: "center",
                    }}
                >
                    <IconButton variant="solid" color="primary" onClick={() => remCount()}>
                        <RemoveRoundedIcon />
                    </IconButton>
                    <Button variant="solid" disabled color="danger" 
                        sx={(theme) => ({
                            py: 0,
                            fontSize: 'lg',
                            width: 'auto',
                            [theme.breakpoints.down('sm')]: {
                                width: '100%'
                            }
                        })}
                    >
                        {count}
                    </Button>
                    <IconButton variant="solid" color="primary" onClick={translateDish}>
                        <AddRoundedIcon />
                    </IconButton>
                </Stack>
            }
        </Card>
    )
}