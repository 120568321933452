import { Card, CardContent, CardCover, Typography } from "@mui/joy";
import { Link } from "react-router-dom";


export default function CategoryItem({ item }) {
    return (
        <Link to={`/category/${item.slugID}`} style={{ textDecoration: 'none' }}>
            <Card 
                variant="plain"
                sx={{ 
                    height: 100,
                    transition: 'transform .3s ease-in-out',
                    '&:hover': { 
                        cursor: 'pointer',
                        transform: 'scale(1.03)', 
                        transition: 'transform .3s ease-in-out',
                        borderColor: 'neutral.outlinedHoverBorder' 
                    }
                }}
            >
                <CardCover>
                    <img
                        src={item.img}
                        loading="lazy"
                        alt=""
                    />
                </CardCover>
                <CardCover
                    sx={{ background: 'rgba(0,0,0,0.4)'}}
                />
                <CardContent sx={{ justifyContent: 'flex-end' }}>
                    <Typography
                        level="title-md"
                        textColor="#fff"
                        sx={{ fontWeight: 'lg' }}
                    >
                        {item.title}
                    </Typography>
                </CardContent>
            </Card>
        </Link>
    )
}