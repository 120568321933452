import { Box, Button, Container, Grid, Link, Stack, Typography } from "@mui/joy";
import Header from "../UI/header/Header";
import ChipSlider from "../components/ChipSlider/ChipSlider";
import SliderBanner from "../components/Banners/SliderBanner";
import ItemProduct from "../components/ItemProduct/ItemProduct";
import Category from "../components/Catagory/Category";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { LayoutContext } from "../context";
import { useContext } from "react";


export default function CategoryPage() {
    const { slugID, slugGroup } = useParams(); // захватываем параметр из URL
    const { textTranslate } = useContext(LayoutContext);

    var products = [
        {id: 1, title: 'Лагман классический', img: 'https://www.edimdoma.ru/system/images/contents/0000/5151/wide/AdobeStock_237892293.jpg?1552991680', text: 'products'},
        {id: 2, title: 'Фо бо', img: 'https://www.edimdoma.ru/system/images/contents/0000/5152/wide/AdobeStock_232847632.jpg?1552991681', text: 'gotovaya-eda'},
        {id: 3, title: 'Куксу по-корейски', img: 'https://www.edimdoma.ru/system/images/contents/0000/5153/wide/AdobeStock_137839867.jpg?1552991681', text: 'tovari'}
    ]

    return (
        <Box>
            <ChipSlider categoryID={slugID} slugGroup={slugGroup}/>
            <Container maxWidth="sm" sx={{ my: 3 }}>
                <Typography level="h2" mb={2}>
                    Категории
                </Typography>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <img src='/shark_load.gif' height={150} style={{ borderRadius: '20px', objectFit: 'cover' }} />
                    <Typography sx={{ backgroundColor: 'neutral.50', py: 1, px: 1.5, borderRadius: 'md', textAlign: 'center' }}>
                        {textTranslate.loading} ...
                    </Typography>
                </Stack>
                {/* <Grid
                    container 
                    spacing={{ xs: 2 }}
                    columns={{ xs: 4, sm: 8, md: 10 }}
                    sx={{ flexGrow: 1 }}
                >
                    {products.map((product) => (
                    <Grid item xs={2} sm={4} md={5} key={product.id}>
                        <ItemProduct item={product} />
                    </Grid>
                ))}
                </Grid> */}
            </Container>
        </Box>
    )
}