import React, { useContext, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode } from 'swiper/modules';
import { Chip } from '@mui/joy';
import ChipSliderItem from './ChipSliderItem';
import { Link as RouterLink } from 'react-router-dom';
import { LayoutContext } from '../../context';


export default function ChipSlider({ categoryID, slugGroup }) {
    const { textTranslate } = useContext(LayoutContext);

    var group = [
        {title: 'Продукты', slug: 'product' },
        {title: 'Продукты', slug: 'product2' },
        {title: 'Продукты', slug: 'product3' },
        {title: 'Продукты', slug: 'product4' },
        {title: 'Продукты', slug: 'product5' },
    ]

    
    function ChipItem(title, slug) {
        return <Chip
                    variant={ slug == slugGroup ? "solid" : "plain" }
                    color="neutral"
                    size="lg"
                    sx={{
                        "--Chip-minHeight": "40px",
                        "--Chip-radius": "15px",
                        "--Chip-gap": "29px",
                        "--Chip-paddingInline": "20px"
                    }}
                    // onClick={() => alert('You clicked the Joy Chip!')}
                    >
                    {title}
                </Chip>
    }



    return (
        <Swiper
            slidesPerView="auto"
            spaceBetween={10}
            freeMode={true}
            style={{ margin: '10px 0', padding: '0 15px', width: 'auto' }}
            modules={[FreeMode]}
            className="ChipSwiper"
        >
            <SwiperSlide style={{ width: 'auto' }}>
                <RouterLink to={`/category/${categoryID}`}>
                    {ChipItem(textTranslate.all, slugGroup)}
                </RouterLink>
            </SwiperSlide>
            {
                group.map((item) => ( 
                    <SwiperSlide style={{ width: 'auto' }}>
                        <RouterLink to={`/category/${categoryID}/${item.slug}`}>
                            {ChipItem(item.title, item.slug)}
                        </RouterLink>
                    </SwiperSlide>
                ))
            }
            
        </Swiper>
    )
}