import { Box, Button, Card, Chip, CircularProgress, IconButton, Stack, Typography } from "@mui/joy";
import { useContext, useState } from "react";
import { LayoutContext } from "../../context";

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import StepperOrder from "./StepperOrder";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

export default function OrdersItem({ item }) {
    const [ count, setCount ] = useState(1);
    const { menu, setMenu } = useContext(LayoutContext);

    const [ image, setImage ] = useState(undefined);

    // // Подгружаем картинку товара
    // useEffect(() => {
    //     setImage(API_URL + item.product?.image);
    //     if (item?.product?.image !== null) {
    //         setImage(API_URL + item?.product?.image);
    //     } else setImage(undefined);
    //     // loadImageProduct(item.product, setImage, menu, setMenu);
    // }, [])


    function delBask (){
        // removeBasket(0, item.product);
        // item.setCount(item.count - 1);
        setCount(count - 1);
    }

    function plusBask (){
        // addBasket(item.count + 1, item.setCount, item.product);
        // item.setCount(item.count + 1);
        setCount(count + 1);
    }

    return (
        <Stack
            justifyContent="space-between"
            alignItems="stretch"
            spacing={{ xs: 1, sm: 2 }}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{
                p: 2,
                boxShadow: 'md',
                borderRadius: 'md'
            }}
        >
            <Stack
                direction='row'
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >
                <StepperOrder/>
                <Stack
                    sx={{ height: '100%', width: '100%' }}
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0.1}
                >   
                    <Stack
                        sx={{ height: '100%', width: '100%' }}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={0.5}
                    >
                        <Typography level='h4'>
                            Заказ №3523
                        </Typography>
                        <Chip color="warning" size="sm">
                            Доставляется
                        </Chip>
                    </Stack>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={-0.8}
                    >
                        <Typography>
                            Стоимость:
                        </Typography>
                        <Typography fontWeight='xl' level="h4">
                            {/* {item?.product?.mitm_Price} ₽ */}
                            1000 ₽
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={0.8}
                        sx={{ width: '100%' }}
                    >
                        <Button 
                            color='neutral'
                            sx={{ px: 1 }} 
                            variant="soft"
                        >
                            <InfoRoundedIcon sx={{ fontSize: 20 }} />
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}