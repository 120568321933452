import { AspectRatio, Box, Button, ButtonGroup, DialogContent, IconButton, Link, Modal, ModalClose, ModalDialog, ModalOverflow, Skeleton, Stack, Typography, useTheme } from "@mui/joy";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Transition } from "react-transition-group";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { LayoutContext } from "../../context";

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import SliderDemonstProduct from "./SliderDemonstProduct";


function ModalProduct ({ }) {
    const [ count, setCount ] = useState(0);
    console.log('Modal');
    // console.log(modal.product);

    const { modalOpen, setOpenModal, setModalCompany } = useContext(LayoutContext);
    const { modalProduct, setModalProduct } = useContext(LayoutContext);

    const [ image, setImage ] = useState(undefined);
    // useEffect(() => {
    //     if (modal?.product?.image !== null) {
    //         setImage(API_URL + modal?.product?.image);
    //     } else setImage(undefined);
    //     // Подгружаем картинку товара
    //     // loadImageProduct(modal.product, setImage, menu, setMenu);
    // }, [modal.product])

    // const isEmpty = (obj) => Object.keys(obj).length === 0;

    // const weight = useMemo(() => {
    //     if (modal.product.hasOwnProperty('Product')) {
    //         return modal.product.Product.hasOwnProperty('cmpd_Output') ? modal.product.Product.cmpd_Output : '-';
    //     } else return ''
    // }, [modal.product])

    // const description = useMemo(() => {
    //     if (modal.product.hasOwnProperty('Product')) {
    //         return modal.product.Product.hasOwnProperty('pitm_Description') ? modal.product.Product.pitm_Description : '-';
    //     } else return ''
    // }, [modal.product])

    function toCompany() {
        setOpenModal(false);
        setModalCompany(true);
    }

    function addCount() {
        setCount(count+1);
    }

    function remCount() {
        if (count-1 <= 0) setCount(0);
        setCount(count-1);
    }
 
    function minusBask (){
        // removeBasket(modal.count - 1, modal.product);
        // modal.setCount(modal.count - 1);
        // setModal({ ...modal, count: modal.count - 1 });
    }

    function plusBask (){
        // addBasket(modal.count + 1, modal.setCount, modal.product);
        // modal.setCount(modal.count + 1);
        // setModal({ ...modal, count: modal.count + 1 });
    }

    return (
        <Transition in={modalOpen} timeout={200}>
            {(state) => (
                <Modal
                    keepMounted
                    open={!['exited', 'exiting'].includes(state)}
                    onClose={() => setOpenModal(false)}
                    slotProps={{
                        backdrop: {
                            sx: {
                            opacity: 0,
                            backdropFilter: 'none',
                            transition: `opacity 200ms, backdrop-filter 500ms`,
                            ...{
                                entering: { opacity: 1, backdropFilter: 'blur(8px)' },
                                entered: { opacity: 1, backdropFilter: 'blur(8px)' },
                            }[state],
                            },
                        },
                    }}
                    sx={{
                        visibility: state === 'exited' ? 'hidden' : 'visible',
                    }}
                >
                    <ModalOverflow sx={{ height: 'auto' }}>
                        <ModalDialog
                            color="primary"
                            variant="plain"
                            sx={(theme) => ({
                                width: 600,
                                pt: 6,
                                [theme.breakpoints.down('sm')]: {
                                    px: 1,
                                    pb: 1,
                                },
                                borderRadius: 'xl',
                                opacity: 0,
                                transition: `opacity 200ms`,
                                ...{
                                entering: { opacity: 1 },
                                entered: { opacity: 1 },
                                }[state],
                            })}
                        >
                            <ModalClose style={{ zIndex: 2 }} />
                            <DialogContent>
                                <Stack
                                    direction={'column'}
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{ height: '100%' }}
                                >
                                    <SliderDemonstProduct/>
                                    {/* <AspectRatio ratio="4/4" sx={{ width: '100%', borderRadius: 'xl',
                                        '& > .MuiAspectRatio-content': { 
                                            ...( image === '' && { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, height: '177px' }
                                        )}
                                    }}>
                                        { image == '' ?
                                        <Box />
                                        :
                                        <Skeleton loading={false} variant="overlay">
                                            <img style={{ borderRadius: 'xl' }} 
                                            width={"100%"} 
                                            src={ image == undefined ? '/default.png' : image }
                                            loading="lazy" alt=""/>
                                        </Skeleton> 
                                    }
                                    </AspectRatio> */}
                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{ px: 1 }}>
                                            <Typography 
                                                sx={(theme) => ({
                                                    [theme.breakpoints.down('small')]: {
                                                        fontSize: theme.fontSize.xl2
                                                    }
                                                    })} 
                                                fontWeight={'bold'} 
                                                level="h3"
                                                >
                                                    Огурецqqqq
                                            </Typography>
                                            <Link color="primary" level="body-md" sx={{ mb: 2 }} onClick={() => toCompany()}>
                                                Shri Lanka Cafe
                                            </Link>
                                            <Typography level='body-sm' lineHeight={1.2}>
                                                Описание компании которую вы нажали, она классная просто вообще топ и ну просто класс который заслуживает вашегно внимания
                                            </Typography>
                                            <Box sx={{ mt: 2 }}>
                                                <Stack 
                                                    sx={{ width: '100%' }}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <Stack
                                                        direction="column"
                                                        justifyContent="center"
                                                        alignItems="flex-start"
                                                        spacing={-0.8}
                                                    >
                                                        <Typography>
                                                            Стоимость:
                                                        </Typography>
                                                        <Typography sx={(theme) => ({
                                                            px: 0, fontWeight: 'xl', color: theme.vars.palette.primary[700],
                                                            [theme.breakpoints.down('small')]: {
                                                                fontSize: theme.fontSize.xl2
                                                            }
                                                            })} level="h2">
                                                            {/* { modal.product.mitm_Price }₽ */}
                                                            200 ₽
                                                        </Typography>
                                                    </Stack>

                                                    {
                                                        count <= 0 ?
                                                            <Button
                                                                sx={{ fontSize: 'md', px: 3, width: 'auto' }}
                                                                onClick={() => addCount()}>
                                                                В корзину
                                                            </Button>
                                                        :
                                                        <Stack
                                                            direction="row"
                                                            spacing={1}
                                                            sx={{
                                                                justifyContent: { xs: 'center', sm: 'flex-end' },
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <IconButton variant="solid" color="primary" onClick={() => remCount()}>
                                                                <RemoveRoundedIcon />
                                                            </IconButton>
                                                            <Button variant="solid" disabled color="danger" 
                                                                sx={(theme) => ({
                                                                    py: 0,
                                                                    fontSize: 'lg',
                                                                    width: 'auto',
                                                                    [theme.breakpoints.down('sm')]: {
                                                                        width: '100%'
                                                                    }
                                                                })}
                                                            >
                                                                {count}
                                                            </Button>
                                                            <IconButton variant="solid" color="primary" onClick={() => addCount()}>
                                                                <AddRoundedIcon />
                                                            </IconButton>
                                                        </Stack>
                                                    }
                                                </Stack>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Stack>
                            </DialogContent>
                        </ModalDialog>
                    </ModalOverflow>
                </Modal>
            )}
        </Transition>
    )
}

export default ModalProduct;