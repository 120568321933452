import React, { useRef } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
import { EffectCoverflow, Autoplay } from 'swiper/modules';
import { Card, CardContent, CardCover, Chip, Divider, Typography } from '@mui/joy';
import ItemProduct from '../ItemProduct/ItemProduct';


export default function SliderProducts() {

    var products = [
        {id: 1, title: 'Помидор', img: 'https://www.medgorod.ru/sites/default/files/styles/gallery-slider-mobile/public/article_photo/produkty.jpg?itok=_T3D-Cnd', text: 'products'},
        {id: 2, title: 'Огурец', img: 'https://aif-s3.aif.ru/images/025/908/141ead4a45cadb2cfa4465242ba861e8.jpg', text: 'gotovaya-eda'},
        {id: 3, title: 'Котлета', img: 'https://avatars.mds.yandex.net/get-pdb/1881324/7e50dd30-89aa-41b6-a5d6-e51689038f5b/s1200?webp=false', text: 'tovari'}
    ]

    return (
        <Swiper
            grabCursor={true}
            spaceBetween={20}
            slidesPerView={2}
            autoplay={{
                delay: 8000
            }}
            modules={[  ]}
            className="SwiperItem"
            style={{ padding: '15px', width: 'auto' }}
        >
            {
                products.map((product) => ( 
                    <SwiperSlide style={{ width: 'auto' }} key={product.id}>
                        <ItemProduct item={product} />
                    </SwiperSlide>
                ))
            }
        </Swiper>
    )
}