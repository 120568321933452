import { Step, stepClasses, StepIndicator, stepIndicatorClasses, Stepper, Typography } from "@mui/joy";

import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import DeliveryDiningRoundedIcon from '@mui/icons-material/DeliveryDiningRounded';

export default function StepperOrder() {
    return (
        <Stepper orientation="vertical"
            size="sm"
            sx={{
                width: 'auto',
                "--Step-connectorThickness": "2px",
                '--StepIndicator-size': '2rem',
                '--Step-connectorInset': '0px',
                "--Step-gap": "5px",
                [`& .${stepIndicatorClasses.root}`]: {
                    borderWidth: 2,
                },
                // [`& .${stepClasses.root}::after`]: {
                //     height: 4,
                // },
                [`& .${stepClasses.completed}`]: {
                    [`& .${stepIndicatorClasses.root}`]: {
                        borderColor: 'primary.300',
                        color: 'primary.300',
                    },
                    '&::after': {
                        bgcolor: 'primary.300',
                    },
                },
                [`& .${stepClasses.active}`]: {
                    [`& .${stepIndicatorClasses.root}`]: {
                        borderColor: 'currentColor',
                    },
                },
                [`& .${stepClasses.disabled} *`]: {
                    color: 'neutral.outlinedDisabledColor',
                },
            }}
        >
            <Step
                orientation="vertical"
                completed
                indicator={
                    <StepIndicator variant="outlined" color="primary">
                        <CreateRoundedIcon />
                    </StepIndicator>
                }
            />
            <Step
                orientation="vertical"
                active
                indicator={
                    <StepIndicator variant="solid" color="warning">
                        <DeliveryDiningRoundedIcon />
                    </StepIndicator>
                }
            />
            <Step
                orientation="vertical"
                disabled
                indicator={
                    <StepIndicator variant="outlined" color="neutral">
                        <DoneAllRoundedIcon />
                    </StepIndicator>
                }
            />
        </Stepper>
    )
}