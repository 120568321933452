import { Card, CardContent, CardCover, Container, CardOverflow, Grid, Typography } from "@mui/joy";
import CategoryItem from "./CategoryItem";




export default function Category() {

    var cat = [
        {title: 'Продукты', img: 'https://www.medgorod.ru/sites/default/files/styles/gallery-slider-mobile/public/article_photo/produkty.jpg?itok=_T3D-Cnd', slugID: 'products'},
        {title: 'Готовая еда', img: 'https://aif-s3.aif.ru/images/025/908/141ead4a45cadb2cfa4465242ba861e8.jpg', slugID: 'gotovaya-eda'},
        {title: 'Товары', img: 'https://avatars.mds.yandex.net/get-pdb/1881324/7e50dd30-89aa-41b6-a5d6-e51689038f5b/s1200?webp=false', slugID: 'tovari'}
    ]
    return (
        <Grid 
            container 
            spacing={{ xs: 2 }}
            columns={{ xs: 4, sm: 8, md: 10 }}
            sx={{ flexGrow: 1 }}
        >
            {cat.map((category) => (
                <Grid item xs={2} sm={4} md={5} key={category}>
                    <CategoryItem item={category}/>
                </Grid>
            ))}
        </Grid>
    )
}